<template>
  <div v-if="selectedAlumno">
    <b-overlay
      spinner-variant="primary"
      :show="spinner"
      variant="semi-dark"
    >
      <alumnosForm
        btnSubmit="Editar Alumno"
        :alumno="selectedAlumno"
        @processForm="editar"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import alumnosForm from './components/AlumnosForm.vue'

export default {
  components: {
    alumnosForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('alumnos', ['selectedAlumno']),
  },
  methods: {
    ...mapActions({ updateAlumno: 'alumnos/updateAlumnos' }),
    editar(alumno) {
      this.spinner = true
      this.updateAlumno(alumno).then(() => {
        const errorAlumnos = store.state.alumnos
        const errorMessage = errorAlumnos.errorMessage.errors
        if (!errorAlumnos.error) {
          let title = ''
          let text = ''
          if (alumno.genero === 'Femenino') {
            title = 'Alumna editado 👍'
            text = `La alumna "${alumno.nombres} ${alumno.primerApellido}
              ${alumno.segundoApellido}" fue editada con éxito!`
          } else {
            title = 'Alumno editado 👍'
            text = `El alumno "${alumno.nombres} ${alumno.primerApellido}
              ${alumno.segundoApellido}" fue editado con éxito!`
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title,
              text,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'alumnos',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorAlumnos.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
